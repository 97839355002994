<template>
  <div class="">
    <div class="card">
      <div class="card-body">
        <h4
          class="card-title"
          :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'txt-left'"
        >
          {{ label }}
        </h4>

        <div class="tab-danger pt-3">
          <!-- Provider -->
          <div v-if="hasProvider" class="form-group col-12">
            <label
              for="input1"
              class="col-form-label"
              :class="
                $i18n.locale == 'ar'
                  ? 'hacen_algeria font-size-15'
                  : 'float-left'
              "
            >
              {{ labelProvider }}
            </label>
            <input
              id="input1"
              type="text"
              class="form-control"
              style="direction: ltr"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
              v-model="row.provider"
              v-on:input="onFormChange"
            />
          </div>
          <!-- End Provider -->

          <!-- Provider URL -->
          <div v-if="hasProvider" class="form-group col-12">
            <label
              for="input2"
              class="col-form-label"
              :class="
                $i18n.locale == 'ar'
                  ? 'hacen_algeria font-size-15'
                  : 'float-left'
              "
            >
              {{ labelProviderURL }}
            </label>
            <input
              id="input2"
              type="text"
              class="form-control"
              style="direction: ltr"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
              v-model="row.provider_url"
              v-on:input="onFormChange"
            />
          </div>
          <!-- End Provider URL -->

          <!-- Name -->
          <div v-if="hasName" class="form-group col-12">
            <label
              for="input3"
              class="col-form-label"
              :class="
                $i18n.locale == 'ar'
                  ? 'hacen_algeria font-size-15'
                  : 'float-left'
              "
            >
              {{ labelName }}
            </label>
            <input
              id="input3"
              type="text"
              autocomplete="off"
              :readonly="$props.readonly"
              class="form-control"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
              v-model="row.name"
              v-on:input="onFormChange"
              required=""
            />
          </div>
          <!-- End Name -->

          <!-- Email -->
          <div v-if="hasEmail" class="form-group col-12">
            <label
              for="input4"
              class="col-form-label"
              :class="
                $i18n.locale == 'ar'
                  ? 'hacen_algeria font-size-15'
                  : 'float-left'
              "
            >
              {{ labelEmail }}
            </label>
            <input
              id="input4"
              type="email"
              autocomplete="off"
              class="form-control"
              :readonly="$props.readonly"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
              v-model="row.email"
              v-on:input="onFormChange"
              required=""
            />
          </div>
          <!-- End Email -->

          <!-- Password -->
          <div v-if="hasPassword" class="form-group col-12">
            <label
              for="input5"
              class="col-form-label"
              :class="
                $i18n.locale == 'ar'
                  ? 'hacen_algeria font-size-15'
                  : 'float-left'
              "
            >
              {{ labelPassword }}
            </label>
            <input
              id="input5"
              type="password"
              autocomplete="off"
              class="form-control"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
              v-model="row.password"
              v-on:input="onFormChange"
              :placeholder="name ? '****************' : ''"
            />
          </div>
          <!-- End Password -->

          <!-- Country Code -->
          <div v-if="hasCountryCode" class="form-group col-12">
            <label
              for="input6"
              class="col-form-label"
              :class="
                $i18n.locale == 'ar'
                  ? 'hacen_algeria font-size-15'
                  : 'float-left'
              "
            >
              {{ labelCountryCode }}
            </label>
            <input
              id="input6"
              type="text"
              autocomplete="off"
              class="form-control"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
              v-model="row.country_code"
              v-on:input="onFormChange"
              required=""
            />
          </div>
          <!-- End Mobile -->

          <!-- Mobile -->
          <div v-if="hasMobile" class="form-group col-12">
            <label
              for="input7"
              class="col-form-label"
              :class="
                $i18n.locale == 'ar'
                  ? 'hacen_algeria font-size-15'
                  : 'float-left'
              "
            >
              {{ labelMobile }}
            </label>
            <input
              id="input7"
              type="text"
              autocomplete="off"
              class="form-control"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
              v-model="row.mobile"
              v-on:input="onFormChange"
              required=""
            />
          </div>
          <!-- End Mobile -->

          <!-- Title -->
          <div v-if="hasTitle" class="form-group col-12">
            <label
              for="input8"
              class="col-form-label"
              :class="
                $i18n.locale == 'ar'
                  ? 'hacen_algeria font-size-15'
                  : 'float-left'
              "
            >
              {{ labelTitle }}
            </label>
            <input
              id="input8"
              type="text"
              class="form-control"
              @paste="onPaste"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
              v-model="row.title"
              v-on:input="onInputChange"
            />
          </div>
          <!-- End Title -->

          <!-- Body -->
          <div v-if="hasBody" class="form-group col-12">
            <label
              for="editor1"
              class="col-form-label"
              :class="
                $i18n.locale == 'ar'
                  ? 'hacen_algeria font-size-15'
                  : 'float-left'
              "
            >
              {{ labelBody }}
            </label>
            <editor
              id="editor1"
              v-model="row.body"
              :readonly="$props.readonly"
              v-on:input="onFormChange"
              :api-key="editor.api_key"
              :init="{
                height: 300,
                menubar: editor.menubar,
                plugins: editor.plugins,
                toolbar: editor.toolbar,
              }"
            />
          </div>
          <!-- End Body -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "FormElements",
  components: {
    Editor,
  },
  props: [
    "label",

    "labelName",
    "labelEmail",
    "labelCountryCode",
    "labelMobile",
    "labelPassword",

    "labelProvider",
    "labelProviderURL",

    "labelTitle",
    "labelBody",

    "hasName",
    "hasEmail",
    "hasCountryCode",
    "hasMobile",
    "hasPassword",

    "hasProvider",
    "hasProviderURL",

    "hasTitle",
    "hasBody",

    "name",
    "email",
    "mobile",
    "country_code",
    "password",

    "provider",
    "provider_url",

    "title",
    "body",

    "readonly",
    "no_space",
  ],
  data() {
    return {
      row: {
        provider: this.$props.provider,
        provider_url: this.$props.provider_url,

        name: this.$props.name,
        email: this.$props.email,
        country_code: this.$props.country_code,
        mobile: this.$props.mobile,
        password: this.$props.password,

        title: this.$props.title,
        body: this.$props.body,
      },

      editor: {
        api_key: window.editor_apiKey,
        menubar: window.editor_menubar,
        plugins: window.editor_plugins,
        toolbar: window.editor_toolbar,
      },
    };
  },
  mounted() {},
  methods: {
    onFormChange() {
      const data = {
        provider: this.row.provider,
        provider_url: this.row.provider_url,

        name: this.row.name,
        email: this.row.email,
        country_code: this.row.country_code,
        mobile: this.row.mobile,
        password: this.row.password,

        title: this.row.title,
        body: this.row.body,
      };
      this.$emit("formChange", data);
    },

    onPaste() {
      if (this.$props.no_space) {
        let str = this.row.title;
        this.preventSpace(str);
      }
    },

    onInputChange() {
      if (this.$props.no_space) {
        let str = this.row.title;
        this.preventSpace(str);
      }
      this.onFormChange();
    },

    preventSpace(str) {
      if (!str) {
        let str = this.row.title;
        this.row.title = str.replace(/\s+/g, "_");
      } else {
        this.row.title = str.replace(/\s+/g, "_");
      }
    },
  },
};
</script>
